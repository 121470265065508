<script>
/**
 * Login component
 */
export default {
  name: "Login",
};
</script>

<template>
  <div>
    <div class="account-home-btn d-none d-sm-block">
      <a href="/" class="text-white">
        <i class="mdi mdi-home h1"></i>
      </a>
    </div>
    <section class="bg-account-pages height-100vh">
      <div class="display-table">
        <div class="display-table-cell">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-5">
                <div class="card account-card">
                  <div class="card-body">
                    <div class="text-center mt-3">
                      <h3 class="font-weight-bold">
                        <a href="/" class="text-dark text-uppercase account-pages-logo">CEPTE IK</a>
                      </h3>
                      <p class="text-muted">Giriş Yap.</p>
                    </div>
                    <div class="p-3">
                      <form>
                        <div class="form-group">
                          <label for="username">E-Mail</label>
                          <input
                            type="text"
                            class="form-control"
                            id="username"
                            placeholder="Enter username"
                          />
                        </div>

                        <div class="form-group">
                          <label for="userpassword">Şifre</label>
                          <input
                            type="password"
                            class="form-control"
                            id="userpassword"
                            placeholder="Enter password"
                          />
                        </div>

                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="customControlInline"
                          />
                          <label class="custom-control-label" for="customControlInline">Beni Hatırla</label>
                        </div>

                        <div class="mt-3">
                          <button type="submit" class="btn btn-custom btn-block">Giriş Yap</button>
                        </div>

                        <div class="mt-4 mb-0 text-center">
                          <router-link tag="a" to="/forgot-password" class="text-dark">
                            <i class="mdi mdi-lock"></i> Şifremi unuttum
                          </router-link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <!-- end card -->
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
        </div>
      </div>
    </section>
    <!-- end account-pages  -->
  </div>
</template>
